<template>
  <div class="blacklist">
    <div class="content">
      <p class="title">籌款人黑名單</p>
      <p>
        iMeddy籌款平臺認為信任是本平臺最重要的財產，對於編造或誇大求助信息、隱瞞個人財產信息，製造假病歷、不當挪用醫療款等行為的惡意籌款人，
        以黑名單形式進行公示，並持續更新。對於進入失信籌款人黑名單的用戶，iMeddy籌款平臺不再為其提供服務，並付諸法律責任。
      </p>
      <p>
        iMeddy籌款平臺呼籲廣大市公所，媒體記者、行業專家、醫護人員、熱心網友等各方面社會力量持續關註並支持個人大病求助互聯網平臺工作，
        積極監督和舉報不誠信行爲，捍衛廣大愛心人士的善行善舉，讓真正陷入困境的大病患者能夠看到希望，得到及時救助。
      </p>
    </div>
    <div class="record" v-if="content_list.length == 0">
      <h1>未有記錄</h1>
      <img src="../../assets/img/blacklist.png"/>
    </div>
    <div class="records" v-else>
      <h1 align="left">籌款人黑名單</h1>
      <div style="margin-top:40px;" v-loading="loading"  element-loading-text="加載內容中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table
            :data="content_list"
            style="width: 100%">
            <el-table-column
              prop="phone"
              label="手機"
              width="180">
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              width="180">
            </el-table-column>
            <el-table-column
              prop="title"
              label="標題">
              </el-table-column>
              <el-table-column
              prop="identity_card"
              label="身份證號碼">
              </el-table-column>
              <el-table-column
              prop="time"
              label="列入黑名單日期">
            </el-table-column>
          </el-table>
          <br />
          <br />
          <br />
          <br />
          <el-pagination
            background
            class="pagination"
            layout="prev, pager, next"
            :total="page_total"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="handleCurrentChange"
            >
          </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
export default {
  data(){
    return {
      content_list:[],
      // 總頁數
      page_total:0,
      // 當前頁數
      antorbital_page:1,
      // 每頁條數
      page_number:10,
      loading:false,
      i:0,
      j:0,
    }
  },
  methods:{
    handleCurrentChange(Page){
      this.antorbital_page = Page;
      this.contents_list();
    },
    contents_list(){
      this.loading = true;
      api.fundraiseBlacklist.getFundraiseBlacklist('?status=1&pageNum='+this.antorbital_page+'&pageSize='+this.page_number).then(response => {
        if(response.code == 200){
          let content_list = response.data.results;

          this.page_total = Math.ceil(response.data.count / this.page_number) * 10;
          

          for(let i=0; i<content_list.length; i++){
            if(content_list[i].mobileNumber){
              content_list[i].phone = this.mobileStr(content_list[i].mobileNumber);
            }else{
              content_list[i].phone = '';
            }
            // content_list[i].identity_card = this.mobileStr(content_list[i].identity_card);
            content_list[i].identity_card = this.mobileStr('412131299911012132');
            let time = new Date(content_list[i].createDatetime);
            var Y = time.getFullYear()
            var M = (time.getMonth()+1 < 10 ? '0'+(time.getMonth()+1) : time.getMonth()+1)
            var D = time.getDate()

            content_list[i].time = Y+'年'+M+'月'+D+'日';

            content_list[i].name = content_list[i].realName;

            content_list[i].title = content_list[i].fundraise.project.title;
          }

          this.content_list = content_list;
        
        }else{
          this.$message.error(response.msg);
        }
        this.loading = false;
      }).catch(error=>{
          this.$message.error('數據異常，請重新獲取！');
          this.loading = false;
      });
    },
    mobileStr(str) {
      if(str.length>7){
        return  str.substr(0,3)+'****'+str.substr(7,str.length);
      }else {
        return str.substr(0,str.length-1)+'****';
      }
    },
  },
  created(){
    this.contents_list();
  }
};
</script>

<style scoped>
li{
  float: left;
  height:auto;
}
.records{
  width: 100%;
  height: 1000px;
  text-align: center;
  padding: 30px 30px;
}
.records h1 {
  font-size: 38px;
  color: #157272;
}
.blacklist{
  margin-top:80px;
}
.content {
  height: 650px;
  background: url("../../assets/img/aboutusbg.jpg") no-repeat bottom;
  background-size: cover;
  text-align: left; 
}
.title {
  font-size: 36px;
  padding-top: 150px;
  padding-left: 100px;
  font-size: 50px;
}
p {
  color: #fff;
  padding-top: 40px;
  padding-left: 100px;
  font-size: 22px;
  letter-spacing: 3px;
  width: 75%;
}
.record h1 {
  padding-top: 50px;
  font-size: 38px;
  color: #157272;
}
.record {
  width: 100%;
  height: 1000px;
  text-align: center;
}
.pagination ::v-deep .btn-next .el-icon, .pagination ::v-deep .btn-prev .el-icon {
  margin: 0 auto;
}
</style>